import { template as template_85c9aac644cd457ebe8af6048d7c6b6f } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { getURLWithCDN } from "discourse-common/lib/get-url";
export default class CdnImg extends Component {
    get cdnSrc() {
        return getURLWithCDN(this.args.src);
    }
    get style() {
        if (this.args.width && this.args.height) {
            return htmlSafe(`--aspect-ratio: ${this.args.width / this.args.height};`);
        }
    }
    static{
        template_85c9aac644cd457ebe8af6048d7c6b6f(`
    {{#if @src}}
      <img
        ...attributes
        src={{this.cdnSrc}}
        width={{@width}}
        height={{@height}}
        style={{this.style}}
        alt=""
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
