import { template as template_97ae09a668f34a3bb2ae75ff4e27439a } from "@ember/template-compiler";
const FKControlMenuContainer = template_97ae09a668f34a3bb2ae75ff4e27439a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
