import { template as template_643e9a8f19c3400abf49626f9b0c4c05 } from "@ember/template-compiler";
const WelcomeHeader = template_643e9a8f19c3400abf49626f9b0c4c05(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
